import React, { useEffect, useState } from "react";
import LogoOn from "../assets/toggle_on.png";
import LogoOff from "../assets/toggle_off.png";

interface InfoVisibilityModal {
  isOpen: boolean;
  onClose: () => void;
}

export function InfoVisibilityModal({ isOpen, onClose }: InfoVisibilityModal) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[999] ">
      <div className="bg-[#202123] rounded-lg w-full max-w-md p-6 relative">
        <div className="">
          <h2 className="text-xl font-semibold text-white mb-4 text-center">
            Visibilité du profil
          </h2>
          <p className="text-sm text-gray-400">
            Vous pouvez modifier la visibilité de votre profil à tout moment.
          </p>
          <img className="w-20 h-auto mt-4" src={LogoOn} />
          <p className="text-sm text-gray-400">
            Lorsque votre profil est public, tous les utilisateurs peuvent
            consulter votre profil et vos publications librement, sans avoir
            besoin d'être abonné à vous.{" "}
          </p>
          <img className="w-20 h-auto mt-10" src={LogoOff} />
          <p className="text-sm text-gray-400">
            Lorsque votre profil est privé , seul les utilisateurs abonnés à
            vous peuvent consulter votre profil et vos publications.{" "}
          </p>

          <div className="flex justify-end items-center gap-4 w-full mt-4">
            <button
              type="button"
              onClick={onClose}
              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-400 hover:text-white transition-colors"
            >
              Compris
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
