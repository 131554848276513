import React, { useContext, useState } from "react";
import { AuthContext } from "../context/Auth.context";
import { NewPost } from "./NewPost";
import { PostGrid } from "./posts/PostGrid";
import { PlusIcon } from "lucide-react";
import { NewPostModal } from "./NewPostModal";

interface ProfilePostsProps {
  profile: any;
  loadUserProfile: () => void;
}

export function ProfilePosts({ profile, loadUserProfile }: ProfilePostsProps) {
  const { user } = useContext<any>(AuthContext);
  const [modalNewPostIsOpen, setModalNewPostIsOpen] = useState<boolean>(false);

  if (!profile) return null;

  const isOwnProfile = user.id === profile.user.id;

  return (
    <div className="space-y-6">
      {isOwnProfile && (
        <div className="bg-[#202123] rounded-lg p-6 pc">
          <NewPost userId={user.id} refreshPublication={loadUserProfile} />
        </div>
      )}

      <PostGrid
        posts={profile.user.publications.sort(
          (a: any, b: any) => b.created_at - a.created_at
        )}
        currentUserId={user.id}
        refreshPublication={loadUserProfile}
      />
      {isOwnProfile && (
        <div className="mobile">
          <div
            className="rounded-full w-10 h-10 bg-[#009B70] fixed bottom-4 right-4 flex justify-center items-center hover:bg-[#007A5A]"
            onClick={() => setModalNewPostIsOpen(true)}
          >
            <PlusIcon className="text-white w-6 h-6" />
          </div>
        </div>
      )}
      {modalNewPostIsOpen && (
        <NewPostModal
          user={user}
          isOpen={modalNewPostIsOpen}
          onClose={() => setModalNewPostIsOpen(false)}
          onConfirm={() => {
            setModalNewPostIsOpen(false);
            loadUserProfile();
          }}
        ></NewPostModal>
      )}
    </div>
  );
}
