import React, { useEffect, useState } from "react";
import { CheckCircle2 } from "lucide-react";
import { Input } from "./common/Input";
import { notifyError } from "../helpers/Notify.helper";
import { NewPost } from "./NewPost";
import { addFriend, removeFriend } from "../services/Friend/friend.service";
import { AxiosResponse } from "axios";

interface SuggestionProfileModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  user: any;
  profiles: any;
}

export function SuggestionProfileModal({
  isOpen,
  onClose,
  onConfirm,
  user,
  profiles,
}: SuggestionProfileModal) {
  if (
    !isOpen ||
    profiles == null ||
    profiles.admin == null ||
    profiles.user == null
  ) {
    return null;
  }
  useEffect(() => {
    if (profiles.admin[0].id != user.id) {
      handleAddFriend(profiles.admin[0].id);
    }
  }, []);

  const [friendTracking, setFriendTracking] = useState<Array<number>>([]);

  const handleAddFriend = (id: number) => {
    addFriend({ friend: id })
      .then((_: AxiosResponse) => {
        setFriendTracking([...friendTracking, id]);
      })
      .catch((error: any) => {
        console.error(error);
        notifyError("Impossible d'ajouter cet utilisateur en ami");
      });
  };

  const handleDeleteFriend = (id: number) => {
    removeFriend(id)
      .then((_: AxiosResponse) => {
        setFriendTracking(friendTracking.filter((f) => f !== id));
      })
      .catch((error: any) => {
        console.error(error);
        notifyError("Impossible de supprimer cet ami");
      });
  };
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[999] ">
      <div className="bg-[#202123] rounded-lg w-full max-w-md p-6 relative">
        <div className="text-center w-full">
          <h2 className="text-xl font-semibold text-white mb-4">
            Débutez en suivant quelques profils..
          </h2>
        </div>
        <div className="w-full">
          {profiles?.admin?.map((profile: any) => (
            <div className="flex items-center justify-between gap-4 mt-4 w-full">
              <div className="flex items-center justify-center gap-4">
                <img
                  src={
                    import.meta.env.VITE_SERVICE_API_URL + "" + profile.avatar
                  }
                  alt="avatar"
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <div className="flex flex-col">
                    <h3 className="text-sm font-semibold text-white">
                      {profile.first_name} {profile.last_name}{" "}
                      <span className="bg-[#009B70] text-[8px] ml-2 text-white px-1 py-0.5 rounded-lg">
                        Administrateur
                      </span>
                    </h3>
                    <span className="text-[8px] text-gray-400">
                      @{user.tag_name}
                    </span>
                  </div>
                </div>
              </div>
              {/* <button
                type="button"
                className="flex items-center gap-2 px-2 py-1 text-xs bg-[#009B70] text-white rounded-lg
                        hover:bg-[#007B56] transition-colors"
              >
                Suivre
              </button> */}
              <CheckCircle2 size={24} className="text-[#009B70]" />
            </div>
          ))}
        </div>
        <div className="bg-gray-500 h-[1px] my-4" />
        <div className="w-full">
          {profiles?.user
            ?.filter((u: any) => u.id != user.id)
            ?.map((profile: any) => (
              <div className="flex items-center justify-between gap-4 w-full">
                <div className="flex items-center justify-center gap-4">
                  <img
                    src={
                      import.meta.env.VITE_SERVICE_API_URL + "" + profile.avatar
                    }
                    alt="avatar"
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <div className="flex flex-col">
                      <h3 className="text-sm font-semibold text-white">
                        {profile.first_name} {profile.last_name}{" "}
                      </h3>
                      <span className="text-[8px] text-gray-400">
                        @{user.tag_name}
                      </span>
                    </div>
                  </div>
                </div>
                {friendTracking.includes(profile.id) ? (
                  <CheckCircle2
                    size={24}
                    className="text-[#009B70] hover:text-red-500 cursor-pointer"
                    onClick={() => handleDeleteFriend(profile.id)}
                  />
                ) : (
                  <button
                    type="button"
                    onClick={() => handleAddFriend(profile.id)}
                    className="flex items-center gap-2 px-2 py-1 text-xs bg-[#009B70] text-white rounded-lg
                          hover:bg-[#007B56] transition-colors"
                  >
                    Suivre
                  </button>
                )}
              </div>
            ))}
        </div>
        <div className="flex justify-end items-center gap-4 w-full mt-4">
          <button
            type="button"
            onClick={onClose}
            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-400 hover:text-white transition-colors"
          >
            Terminer
          </button>
          {/* <div className="flex-1" /> */}
          {/* <button
            type="submit"
            onClick={() => onConfirm()}
            className="flex items-center gap-2 px-4 py-2 text-sm bg-[#009B70] text-white rounded-lg
                       hover:bg-[#007B56] transition-colors"
          >
            Confirmer
          </button> */}
        </div>
      </div>
    </div>
  );
}
