import React, { useEffect, useState } from "react";
import { CheckCircle2 } from "lucide-react";
import { Input } from "./common/Input";
import { notifyError } from "../helpers/Notify.helper";
import { NewPost } from "./NewPost";

interface NewPostModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  user: any;
}

export function NewPostModal({
  isOpen,
  onClose,
  onConfirm,
  user,
}: NewPostModal) {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[999] ">
      <div className="bg-[#202123] rounded-lg w-full max-w-md p-6 relative">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-white mb-4">
            Nouvelle publication
          </h2>
        </div>
        <NewPost userId={user.id} refreshPublication={onConfirm} />

        <div className="flex justify-end items-center gap-4 w-full mt-4">
          <button
            type="button"
            onClick={onClose}
            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-400 hover:text-white transition-colors"
          >
            Fermer
          </button>
          {/* <div className="flex-1" /> */}
          {/* <button
            type="submit"
            onClick={() => onConfirm()}
            className="flex items-center gap-2 px-4 py-2 text-sm bg-[#009B70] text-white rounded-lg
                       hover:bg-[#007B56] transition-colors"
          >
            Confirmer
          </button> */}
        </div>
      </div>
    </div>
  );
}
